import { IuserStatus } from './types'

//export const apiUrl = '/sp4/APIs/'
export const apiUrl = '/APIs/'

export const userStatusList: IuserStatus[] = [
  {
    status: 'admin',
    statusName: 'Admin',
  },
  {
    status: 'technical',
    statusName: 'Technical',
  },
]
