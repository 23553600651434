import { FC } from 'react'
import { ObservationInfo } from '../../components'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { IobservationData } from '../../API/types'

const useStyles = makeStyles()(({ spacing }) => ({
  box: {
    margin: spacing(0.5),
  },
}))

const observationData: IobservationData[] = [
  {
    name: 'Latvija',
    dateFrom: 'Reālā laikā',
    dateTo: '',
    ratio: 89,
    profit: 134000,
    id: 'LV00001',
  },
  {
    name: 'LV, Rīga, Ba22',
    dateFrom: '15.05.24',
    dateTo: '31.05.24',
    ratio: 12,
    profit: 21432,
    id: 'LV00002',
  },
  {
    name: 'Igaunija',
    dateFrom: '01.04.24',
    dateTo: '30.04.24',
    ratio: 57,
    profit: 204737,
    id: 'EE00001',
  },
]

const Observation: FC = () => {
  const { classes } = useStyles()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          //maxWidth: 300,
        }}
      >
        {observationData.map((observation: IobservationData) => (
          <Box className={classes.box} key={`box-${observation.id}`}>
            <ObservationInfo observation={observation} />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default Observation
