import { FC, useCallback, useMemo } from 'react'
import { Modal } from '../../components'
import { useTranslation } from 'react-i18next'
import { IlocationData } from '../../API/types'
import { UseLocationsDelete } from '../../API'

interface DeleteLocationProps {
  handleClickClose: () => void
  locationData: IlocationData[]
  locationId: number
}

const DeleteLocation: FC<DeleteLocationProps> = ({ handleClickClose, locationData, locationId }) => {
  const { t } = useTranslation('')

  const location = useMemo(() => {
    return locationData.find((location) => location.id === locationId)
  }, [locationId, locationData])

  const handleClose = useCallback(() => {
    handleClickClose()
  }, [handleClickClose])

  const handleAccept = useCallback(async () => {
    await UseLocationsDelete(locationId.toString())

    locationData.splice(
      locationData.findIndex((item) => item.id === locationId),
      1,
    )

    handleClickClose()
  }, [locationData, locationId, handleClickClose])

  return (
    <Modal
      isOpen={true}
      handleClose={handleClose}
      handleAccept={handleAccept}
      dialogTitle={t(`Dzēst lokāciju - ` + location?.locationName)}
      acceptTitle={t(`Dzēst`)}
      closeTitle={t(`Atcelt`)}
    ></Modal>
  )
}

export default DeleteLocation
