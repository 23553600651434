import { FC } from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Icontroller } from '../../API/types'

interface IControllerTypeProps {
  controller: Icontroller
}

const ControllerType: FC<IControllerTypeProps> = ({ controller }) => {
  return (
    <Card sx={{ minWidth: 250, maxWidth: 250, paddingBottom: 0, display: 'inline-block' }}>
      <CardContent
        sx={{
          padding: 1,
          '&:last-child': {
            paddingBottom: 1,
          },
        }}
      >
        <Typography sx={{ fontSize: 14, textAlign: 'center' }} color='text.secondary' gutterBottom>
          {controller.controllerName}
        </Typography>

        <Grid container spacing={0} sx={{ paddingTop: 1 }}>
          <Grid item xs={6} sx={{ borderRight: 'solid #4e555a 1px', textAlign: 'center' }} color='text.secondary'>
            {controller.controllerIcon && (
              <img src={`/img/${controller.controllerIcon}.png`} alt={controller.controllerName} />
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, textAlign: 'left', marginLeft: 2 }} color='text.secondary' gutterBottom>
              ID: {controller.controllerId}
            </Typography>
            <Typography sx={{ fontSize: 14, textAlign: 'left', marginLeft: 2 }} color='text.secondary' gutterBottom>
              Ver: {controller.controllerVer}
            </Typography>
            <Typography sx={{ fontSize: 14, textAlign: 'left', marginLeft: 2 }} color='text.secondary' gutterBottom>
              Sensors: {controller.controllerSensors}
            </Typography>
            <Typography sx={{ fontSize: 14, textAlign: 'left', marginLeft: 2 }} color='text.secondary' gutterBottom>
              Locks: {controller.controllerLocks.length}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ControllerType
