import { FC } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Icontroller } from '../../API/types'
import { ControllerType } from '../../components'

import { useGetControllers } from '../../API'

const useStyles = makeStyles()(({ spacing }) => ({
  box: {
    margin: spacing(0.5),
  },
}))

const Controllers: FC = () => {
  const { classes } = useStyles()

  const controllerData = useGetControllers()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          //maxWidth: 300,
        }}
      >
        {controllerData.map((controller: Icontroller) => (
          <Box className={classes.box} key={`box-${controller.controllerId}`}>
            <ControllerType controller={controller} />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default Controllers
