import { FC, useCallback, useMemo, useState } from 'react'
import { ControllerType, Modal } from '../../components'
import { useTranslation } from 'react-i18next'
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { IlocationData, IroomsList } from '../../API/types'
import { UseLocationsCreate, UseLocationsUpdate, useGetControllers, useGetRoomTypes } from '../../API'
import { DoorSliding, Settings, SensorDoor } from '@mui/icons-material'

interface CreateNewLocationProps {
  handleClickClose: () => void
  locationData: IlocationData[]
  selectedLocationId: number
  rootLocationId: string
}

const CreateNewLocation: FC<CreateNewLocationProps> = ({
  handleClickClose,
  locationData,
  selectedLocationId,
  rootLocationId,
}) => {
  const { t } = useTranslation('')

  const controllerData = useGetControllers()
  const roomTypesData = useGetRoomTypes()

  const location = useMemo(() => {
    return locationData.find((location) => location.id === selectedLocationId)
  }, [selectedLocationId, locationData])

  const [locationName, setLocationName] = useState<string>(location ? location.locationName : '')
  const [locationId, setLocationId] = useState<string>(location ? location.locationId : '')
  const [controllerLogin, setControllerLogin] = useState<string>(
    location && location.controllerLogin ? location.controllerLogin : '',
  )
  const [controllerPass, setControllerPass] = useState<string>(
    location && location.controllerPass ? location.controllerPass : '',
  )
  const [controllerPath, setControllerPath] = useState<string>(
    location && location.controllerPath ? location.controllerPath : '',
  )
  const [controllerId, setControllerId] = useState<string>(
    location && location.controllerId ? location.controllerId : '',
  )
  const [roomsList, setRoomsList] = useState<IroomsList[]>(location?.roomsList ? location?.roomsList : [])

  const handleChangeRoomTypesIds = useCallback(
    (lockId: string, value: number) => {
      const newRoomList = [...roomsList]
      const tmpRoom = newRoomList.find((room) => room.lockId === lockId)

      if (tmpRoom) {
        tmpRoom.roomTypeId = value
        setRoomsList(newRoomList)
      } else {
        newRoomList.push({ lockId, roomTypeId: value, roomName: '' })
        setRoomsList(newRoomList)
      }
    },
    [roomsList],
  )

  const handleChangeRoomTypesNames = useCallback(
    (lockId: string, value: string) => {
      const newRoomList = [...roomsList]
      const tmpRoom = newRoomList.find((room) => room.lockId === lockId)

      if (tmpRoom) {
        tmpRoom.roomName = value
        setRoomsList(newRoomList)
      } else {
        newRoomList.push({ lockId, roomTypeId: 0, roomName: value })
        setRoomsList(newRoomList)
      }
    },
    [roomsList],
  )

  const selectedController = useMemo(() => {
    return controllerData.find((controller) => controller.controllerId === controllerId)
  }, [controllerId, controllerData])

  const handleClose = useCallback(() => {
    handleClickClose()
  }, [handleClickClose])

  const handleAccept = useCallback(async () => {
    if (location) {
      const rawLocation: IlocationData = {
        ...location,
        controllerId,
        locationName,
        locationId,
        controllerLogin,
        controllerPass,
        controllerPath,
        roomsList,
      }

      const updatedLocation = await UseLocationsUpdate(rawLocation)

      location.locationName = updatedLocation.locationName
      location.locationId = updatedLocation.locationId
      location.controllerId = updatedLocation.controllerId
      location.controllerLogin = updatedLocation.controllerLogin
      location.controllerPass = updatedLocation.controllerPass
      location.controllerPath = updatedLocation.controllerPath
      location.roomsList = updatedLocation.roomsList
    } else {
      const rawLocation: IlocationData = {
        id: 0,
        parentId: parseInt(rootLocationId),
        locationId: locationId,
        locationName: locationName,
        controllerId: controllerId,
        controllerLogin: controllerLogin,
        controllerPass: controllerPass,
        controllerPath: controllerPath,
        roomsList,
      }

      const newLocation = await UseLocationsCreate(rawLocation)

      locationData.push(newLocation)
    }
    handleClickClose()
  }, [
    roomsList,
    locationData,
    locationName,
    locationId,
    controllerId,
    controllerLogin,
    controllerPass,
    controllerPath,
    handleClickClose,
    location,
    rootLocationId,
  ])

  return (
    <Modal
      isOpen={true}
      handleClose={handleClose}
      handleAccept={handleAccept}
      dialogTitle={selectedLocationId === 0 ? t('Pievienot jaunu lokāciju') : t('Predigēt lokāciju')}
      acceptTitle={selectedLocationId === 0 ? t('Pievienot') : t('Saglabāt')}
      maxWidth={'sm'}
      closeTitle={t('Atcelt')}
    >
      <Grid container spacing={0}>
        <Grid item xs={6} style={{ alignContent: 'center' }}>
          <TextField
            sx={{ marginTop: 2 }}
            label={'Lokācijas ID'}
            size={'small'}
            fullWidth
            variant='outlined'
            value={locationId}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setLocationId(event.target.value)
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ alignContent: 'center' }}>
          <TextField
            sx={{ marginTop: 2 }}
            label={'Lokācijas nosaukums'}
            size={'small'}
            fullWidth
            variant='outlined'
            value={locationName}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setLocationName(event.target.value)
            }}
          />
        </Grid>
      </Grid>

      <FormControl sx={{ marginTop: 2 }} fullWidth={true} size='small'>
        <InputLabel>Kontrolieris</InputLabel>
        <Select
          value={controllerId}
          label='Kontrolieris'
          onChange={(event: SelectChangeEvent) => {
            setControllerId(event.target.value)
          }}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {controllerData.map((controller) => (
            <MenuItem key={`controllerId-${controller.controllerId}`} value={controller.controllerId}>
              {controller.controllerName}({controller.controllerVer})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedController && (
        <div style={{ marginTop: 10, textAlign: 'center' }}>
          <ControllerType controller={selectedController} />

          <Grid container spacing={0}>
            <Grid item xs={4} style={{ alignContent: 'center' }}>
              <TextField
                sx={{ marginTop: 2 }}
                label={'Kontroliera lietotājs'}
                size={'small'}
                fullWidth
                variant='outlined'
                value={controllerLogin}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  setControllerLogin(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={4} style={{ alignContent: 'center' }}>
              <TextField
                sx={{ marginTop: 2 }}
                label={'Kontroliera parole'}
                size={'small'}
                fullWidth
                variant='outlined'
                value={controllerPass}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  setControllerPass(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={4} style={{ alignContent: 'center' }}>
              <TextField
                sx={{ marginTop: 2 }}
                label={'Kontroliera path'}
                size={'small'}
                fullWidth
                variant='outlined'
                value={controllerPath}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  setControllerPath(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {selectedController?.controllerLocks.map((controllerLock, nr) => {
        const selectedRoom = roomsList.find((room) => room.lockId === controllerLock.controllerLockId)

        return (
          <Grid key={`grid-container-${nr}`} container spacing={0}>
            <Grid item xs={8} style={{ alignContent: 'center' }}>
              <FormControl
                key={`formControl-roomTypesIds-${nr}`}
                sx={{
                  marginTop: 2,
                }}
                fullWidth={true}
                size='small'
              >
                <InputLabel>{nr + 1} - Telpas tips</InputLabel>
                <Select
                  key={`roomTypesIds-${nr}`}
                  //value={selectedRoom?.roomTypeId.toString() || ''}
                  value={!selectedRoom?.roomTypeId ? '0' : selectedRoom?.roomTypeId.toString()}
                  label='Telpas tips'
                  onChange={(event: SelectChangeEvent) => {
                    handleChangeRoomTypesIds(controllerLock.controllerLockId, parseInt(event.target.value))
                  }}
                  renderValue={(value) => {
                    const roomType = roomTypesData.find((roomType) => roomType.id === parseInt(value))
                    if (roomType) {
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {roomType.technicalRoom && <Settings fontSize='small' />}
                          {roomType.publicDoor && <DoorSliding fontSize='small' />}
                          {!roomType.technicalRoom && !roomType.publicDoor && <SensorDoor fontSize='small' />}
                          {roomType.roomTypeName}
                        </Box>
                      )
                    } else {
                      return false
                    }
                  }}
                >
                  <MenuItem value={0} key={`controllerId-none`}>
                    <em>None</em>
                  </MenuItem>
                  {roomTypesData.map((roomType) => (
                    <MenuItem key={`controllerId-${roomType.id}`} value={roomType.id}>
                      <ListItemIcon
                        //style={{ display: 'inline-block', padding: '7px 0 0 7px', margin: 0 }}
                        style={{ minWidth: 55 }}
                      >
                        {roomType.technicalRoom && <Settings fontSize='small' />}
                        {roomType.publicDoor && <DoorSliding fontSize='small' />}
                        {!roomType.technicalRoom && !roomType.publicDoor && <SensorDoor fontSize='small' />}
                      </ListItemIcon>
                      <ListItemText
                      //style={{ display: 'inline-block', padding: '7px 0 0 7px', margin: 0 }}
                      >
                        {roomType.roomTypeName}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4} style={{ alignContent: 'center' }}>
              <TextField
                sx={{ marginTop: 2 }}
                key={`roomTypeName-${nr}`}
                label={`${nr + 1} - Telpas nosaukums`}
                size={'small'}
                fullWidth
                variant='outlined'
                value={selectedRoom?.roomName || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  handleChangeRoomTypesNames(controllerLock.controllerLockId, event.target.value)
                }}
              />
            </Grid>
          </Grid>
        )
      })}
    </Modal>
  )
}

export default CreateNewLocation
