import { useEffect, useState } from 'react'
import { Iusers } from './types'
import { apiUrl } from './utils'

export function useGetUsers() {
  const [items, setItems] = useState<Iusers[]>([])
  useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl + 'userAPI.php?controller=usersController&method=getList').then((res) => {
        return res.json()
      })
      setItems(data)
    }
    fetchData()
  }, [])

  return items
}

export async function UseUsersCreate(json: Iusers) {
  async function fetchData() {
    var data: Iusers = await fetch(apiUrl + 'userAPI.php?controller=usersController&method=add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}

export async function UseUsersUpdate(json: Iusers) {
  async function fetchData() {
    var data: Iusers = await fetch(apiUrl + 'userAPI.php?controller=usersController&method=update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}

export async function UseUsersDelete(id: string) {
  async function fetchData() {
    var data: Iusers = await fetch(apiUrl + 'userAPI.php?controller=usersController&method=delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}
