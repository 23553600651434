import { FC, MutableRefObject, useCallback, useMemo, useState } from 'react'
import { Modal } from '../../components'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { IroomTypes } from '../../API/types'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import { UseRoomTypesCreate, UseRoomTypesUpdate } from '../../API'

interface CreateNewRoomTypeProps {
  dataGridRef: MutableRefObject<GridApiCommunity>
  handleClickClose: () => void
  roomTypesData: IroomTypes[]
  id: number
}

const CreateNewRoomType: FC<CreateNewRoomTypeProps> = ({ dataGridRef, handleClickClose, roomTypesData, id }) => {
  const { t } = useTranslation('')

  const roomType = useMemo(() => {
    return roomTypesData.find((location) => location.id === id)
  }, [id, roomTypesData])

  const [roomTypeName, setRoomTypeName] = useState<string>(roomType ? roomType.roomTypeName : '')
  const [technicalRoom, setTechnicalRoom] = useState<boolean>(roomType ? roomType.technicalRoom : false)
  const [publicDoor, setPublicDoor] = useState<boolean>(roomType ? roomType.publicDoor : false)

  const handleClose = useCallback(() => {
    handleClickClose()
  }, [handleClickClose])

  const handleAccept = useCallback(async () => {
    if (roomType) {
      const rawRoomType: IroomTypes = { ...roomType, roomTypeName, technicalRoom, publicDoor }
      const updatedRoomType = await UseRoomTypesUpdate(rawRoomType)

      roomType.roomTypeName = updatedRoomType.roomTypeName
      roomType.technicalRoom = updatedRoomType.technicalRoom
      roomType.publicDoor = updatedRoomType.publicDoor
    } else {
      const rowIds = dataGridRef.current.getAllRowIds()

      const rawRoomType: IroomTypes = {
        id: rowIds.length + 1,
        roomTypeName: roomTypeName,
        technicalRoom: technicalRoom,
        publicDoor: publicDoor,
      }

      const newRoomType = await UseRoomTypesCreate(rawRoomType)

      dataGridRef.current.updateRows([newRoomType])
      //roomTypesData = [...roomTypesData, newRoomType]
    }
    handleClickClose()
  }, [dataGridRef, roomTypeName, technicalRoom, publicDoor /*, roomTypesData*/, handleClickClose, roomType])

  return (
    <Modal
      isOpen={true}
      handleClose={handleClose}
      handleAccept={handleAccept}
      dialogTitle={id === 0 ? t('Pievienot jaunu telpu') : t('Redigēt telpu')}
      acceptTitle={id === 0 ? t('Pievienot') : t('Saglabāt')}
      closeTitle={t('Atcelt')}
    >
      <TextField
        sx={{ marginTop: 2 }}
        label={'Telpas nosaukums'}
        size={'small'}
        fullWidth
        variant='outlined'
        value={roomTypeName}
        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setRoomTypeName(event.target.value)
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            size={'small'}
            checked={technicalRoom}
            onChange={(e, checked) => {
              setTechnicalRoom(checked)
            }}
          />
        }
        label='Tehniska telpa'
      />
      <FormControlLabel
        control={
          <Checkbox
            size={'small'}
            checked={publicDoor}
            onChange={(e, checked) => {
              setPublicDoor(checked)
            }}
          />
        }
        label='Publiskās durvis'
      />
    </Modal>
  )
}

export default CreateNewRoomType
