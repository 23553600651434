import { Typography } from '@mui/material'
import { FC } from 'react'

const NotFound: FC = () => {
  return (
    <>
      <Typography sx={{ fontSize: 60, textAlign: 'center', lineHeight: 1 }} color='text.secondary' gutterBottom>
        Error
        <br />
        404
        <br />
        Page Not Found
      </Typography>
    </>
  )
}

export default NotFound
