import { Icontroller } from './types'
import { useEffect, useState } from 'react'
import { apiUrl } from './utils'

export function useGetControllers() {
  const [items, setItems] = useState<Icontroller[]>([])
  useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl + 'userAPI.php?controller=controllersController&method=getList').then((res) => {
        return res.json()
      })
      setItems(data)
    }
    fetchData()
  }, [])

  return items
}
