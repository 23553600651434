import { FC, useCallback, useState } from 'react'
import { DataGrid, GridColDef, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton } from '@mui/material'

import { DeleteOutline, Edit } from '@mui/icons-material'
import CreateNewUser from './CreateNewUser'
import DeleteUser from './DeleteUser'
import { useGetUsers } from '../../API'

const useStyles = makeStyles()(({ spacing }) => ({
  box: {
    margin: spacing(0.5),
  },
}))

const Users: FC = () => {
  const { t } = useTranslation('')
  const { classes } = useStyles()

  const usersData = useGetUsers()

  const dataGridRef = useGridApiRef()

  const [openCreate, setOpenCreate] = useState<number | null>(null)
  const [openDelete, setOpenDelete] = useState<number | null>(null)

  const handleClickOpenCreate = useCallback((roomTypeId: number) => {
    setOpenCreate(roomTypeId)
  }, [])

  const handleClickCloseCreate = useCallback(() => {
    setOpenCreate(null)
  }, [])

  const handleClickOpenDelete = useCallback((roomTypeId: number) => {
    setOpenDelete(roomTypeId)
  }, [])

  const handleClickCloseDelete = useCallback(() => {
    setOpenDelete(null)
  }, [])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'login', headerName: 'Login', width: 80 },
    { field: 'name', headerName: 'Vārds', width: 80 },
    { field: 'surename', headerName: 'Uzvārds', width: 80 },
    { field: 'email', headerName: 'Epasts', width: 100 },
    { field: 'userStatus', headerName: 'Status', width: 100 },
    {
      field: 'actions',
      headerName: '',
      type: 'number',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <IconButton
              color='success'
              component='div'
              sx={{}}
              onClick={() => {
                handleClickOpenCreate(params.row.id)
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              color='error'
              component='div'
              sx={{}}
              onClick={() => {
                handleClickOpenDelete(params.row.id)
              }}
            >
              <DeleteOutline />
            </IconButton>
          </>
        )
      },
    },
  ]

  return (
    <>
      <Box
        sx={
          {
            //display: 'flex',
            //flexWrap: 'wrap',
            //maxWidth: 300,
          }
        }
      >
        <Box className={classes.box}>
          <DataGrid
            apiRef={dataGridRef}
            rows={usersData}
            columns={columns}
            rowSelection={false}
            disableColumnMenu={true}
            hideFooter={true}
            autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
            }}
            pageSizeOptions={[50, 10]}
          />
        </Box>
        <Box className={classes.box}>
          <Button variant='outlined' size={'small'} onClick={() => handleClickOpenCreate(0)}>
            {t('Pievienot jaunu lietotāju')}
          </Button>
        </Box>
      </Box>

      {openCreate !== null && (
        <CreateNewUser
          dataGridRef={dataGridRef}
          handleClickClose={handleClickCloseCreate}
          usersData={usersData}
          id={openCreate}
        />
      )}

      {openDelete && (
        <DeleteUser
          dataGridRef={dataGridRef}
          handleClickClose={handleClickCloseDelete}
          usersData={usersData}
          id={openDelete}
        />
      )}
    </>
  )
}

export default Users
