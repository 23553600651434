import { FC } from 'react'
import { CalendarMonth } from '@mui/icons-material'
import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import { EditNote, Memory, Euro } from '@mui/icons-material'
import { IobservationData } from '../../API/types'

interface IObservationInfoProps {
  observation: IobservationData
}

const ObservationInfo: FC<IObservationInfoProps> = ({ observation }) => {
  return (
    <Card sx={{ minWidth: 250, maxWidth: 250, paddingBottom: 0 }}>
      <CardContent
        sx={{
          padding: 1,
          '&:last-child': {
            paddingBottom: 1,
          },
        }}
      >
        <Typography sx={{ fontSize: 14, textAlign: 'center' }} color='text.secondary' gutterBottom>
          {observation.name}
        </Typography>

        <Grid container spacing={0} sx={{ backgroundColor: '#3e3e3e', padding: 0.5, paddingBottom: 0 }}>
          <Grid item xs={1}>
            <CalendarMonth />
          </Grid>
          <Grid item xs={11}>
            <Typography
              sx={{ fontSize: 14, textAlign: 'center', marginTop: 1, lineHeight: 1 }}
              color='text.secondary'
              gutterBottom
            >
              {observation.dateFrom}
              {observation.dateTo === '' ? '' : ' - ' + observation.dateTo}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={0} sx={{ paddingTop: 1 }}>
          <Grid item xs={6} sx={{ borderRight: 'solid #4e555a 1px' }}>
            <div style={{ textAlign: 'center' }}>
              <Typography
                component={'span'}
                sx={{ fontSize: 55, margin: 0, lineHeight: 1 }}
                color={'#446941'}
                gutterBottom
              >
                {observation.ratio}
              </Typography>
              <Typography
                component={'span'}
                sx={{ fontSize: 30, margin: 0, lineHeight: 1 }}
                color={'#446941'}
                gutterBottom
              >
                %
              </Typography>
            </div>

            <Typography
              sx={{ fontSize: 20, textAlign: 'center', margin: '10px 0 0 0', lineHeight: 1 }}
              color={'#7d4d53'}
              gutterBottom
            >
              {observation.profit.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
              <Euro sx={{ fontSize: 12 }} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: 'center', marginTop: 0 }}>
              <IconButton color='success' component='div' sx={{ padding: 0 }} disabled>
                <Memory />
              </IconButton>
            </div>
            <div style={{ textAlign: 'center' }}>
              <IconButton color='primary' component='div' sx={{}} disabled>
                <EditNote />
              </IconButton>
            </div>
            <Typography sx={{ fontSize: 14, textAlign: 'center' }} color={'#4e555a'} gutterBottom>
              {observation.id}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ObservationInfo
