import { FC, useCallback, useState } from 'react'

import { LocationInfo } from '../../components'
import CreateNewLocation from './CreateNewLocation'

import { Box, Breadcrumbs, Button, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { IlocationData } from '../../API/types'
import DeleteLocation from './DeleteLocation'
import { useGetControllers, useGetLocationsById, useGetRoomTypes } from '../../API'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles()(({ spacing }) => ({
  box: {
    margin: spacing(0.5),
  },
}))

const Location: FC = () => {
  const { t } = useTranslation('')
  const { classes } = useStyles()

  const rootLocationId = useLocation().pathname.split('/')[2] || '0'

  const [openCreate, setOpenCreate] = useState<number | null>(null)
  const [openDelete, setOpenDelete] = useState<number | null>(null)

  const locationData = useGetLocationsById(rootLocationId)
  const controllers = useGetControllers()
  const roomTypesData = useGetRoomTypes()

  const handleClickOpenCreate = useCallback((locationId: number) => {
    setOpenCreate(locationId)
  }, [])

  const handleClickCloseCreate = useCallback(() => {
    setOpenCreate(null)
  }, [])

  const handleClickOpenDelete = useCallback((locationId: number) => {
    setOpenDelete(locationId)
  }, [])

  const handleClickCloseDelete = useCallback(() => {
    setOpenDelete(null)
  }, [])

  return (
    <>
      <Box
        sx={
          {
            //display: 'flex',
            //flexWrap: 'wrap',
            //maxWidth: 300,
          }
        }
      >
        <Box className={classes.box}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/location'>
              Lokācijas
            </Link>
            {locationData?.currentLocation.map((location: IlocationData) => (
              <Link key={`id-${location.id}`} underline='hover' color='inherit' href={`/location/${location.id}`}>
                {location.locationName}
              </Link>
            ))}
          </Breadcrumbs>
        </Box>

        {locationData?.collection.map((location: IlocationData) => (
          <Box className={classes.box} key={`box-${location.id}`}>
            <LocationInfo
              location={location}
              controllers={controllers}
              roomTypesData={roomTypesData}
              handleClickOpenDelete={handleClickOpenDelete}
              handleClickOpenCreate={handleClickOpenCreate}
            />
          </Box>
        ))}
        <Box className={classes.box}>
          <Button variant='outlined' size={'small'} onClick={() => handleClickOpenCreate(0)}>
            {t('Pievienot jaunu lokāciju')}
          </Button>
        </Box>
      </Box>

      {openCreate !== null && (
        <CreateNewLocation
          handleClickClose={handleClickCloseCreate}
          locationData={locationData?.collection || []}
          selectedLocationId={openCreate}
          rootLocationId={rootLocationId}
        />
      )}
      {openDelete && (
        <DeleteLocation
          handleClickClose={handleClickCloseDelete}
          locationData={locationData?.collection || []}
          locationId={openDelete}
        />
      )}
    </>
  )
}

export default Location
