import { FC } from 'react'
import { RouterProvider } from 'react-router-dom'
import { routerConfig } from './Router'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

const App: FC = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <RouterProvider router={routerConfig} />
    </ThemeProvider>
  )
}

export default App
