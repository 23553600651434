import { useState, useEffect } from 'react'
import { apiUrl } from './utils'
import { IroomTypes } from './types'

export function useGetRoomTypes() {
  const [items, setItems] = useState<IroomTypes[]>([])
  useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl + 'userAPI.php?controller=roomTypesController&method=getList').then((res) => {
        return res.json()
      })
      setItems(data)
    }
    fetchData()
  }, [])

  return items
}

export async function UseRoomTypesCreate(json: IroomTypes) {
  async function fetchData() {
    var data: IroomTypes = await fetch(apiUrl + 'userAPI.php?controller=roomTypesController&method=add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}

export async function UseRoomTypesUpdate(json: IroomTypes) {
  async function fetchData() {
    var data: IroomTypes = await fetch(apiUrl + 'userAPI.php?controller=roomTypesController&method=update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}

export async function UseRoomTypesDelete(id: string) {
  async function fetchData() {
    var data: IroomTypes = await fetch(apiUrl + 'userAPI.php?controller=roomTypesController&method=delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}
