import { Breakpoint } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FC, ReactElement, ReactNode } from 'react'

interface ModalProps {
  children?: ReactNode
  isOpen?: boolean
  maxWidth?: Breakpoint
  fullWidth?: boolean
  handleClose?: () => void
  handleAccept?: () => void
  closeTitle?: string
  acceptTitle?: string
  dialogTitle?: string | ReactElement
}

const Modal: FC<ModalProps> = ({
  children,
  isOpen = false,
  maxWidth = 'xs',
  fullWidth = true,
  handleClose,
  handleAccept,
  closeTitle,
  acceptTitle,
  dialogTitle,
}) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {dialogTitle && <DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>}

      {children && <DialogContent>{children}</DialogContent>}

      <DialogActions>
        {handleAccept && (
          <Button onClick={handleAccept} autoFocus color={'success'}>
            {acceptTitle}
          </Button>
        )}

        {handleClose && <Button onClick={handleClose}>{closeTitle}</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default Modal
