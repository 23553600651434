import { useState, useEffect } from 'react'
import { apiUrl } from './utils'
import { IlocationData, IlocationInfo } from './types'

export function useGetLocations() {
  const [items, setItems] = useState<IlocationData[]>([])
  useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl + 'userAPI.php?controller=locationsController&method=getList').then((res) => {
        return res.json()
      })
      setItems(data)
    }
    fetchData()
  }, [])

  return items
}

export function useGetLocationsById(id: string) {
  const [items, setItems] = useState<IlocationInfo>()
  useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl + 'userAPI.php?controller=locationsController&method=getById', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      }).then((res) => {
        return res.json()
      })
      setItems(data)
    }
    fetchData()
  }, [id])

  return items
}

export async function UseLocationsCreate(json: IlocationData) {
  async function fetchData() {
    var data: IlocationData = await fetch(apiUrl + 'userAPI.php?controller=locationsController&method=add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}

export async function UseLocationsUpdate(json: IlocationData) {
  async function fetchData() {
    var data: IlocationData = await fetch(apiUrl + 'userAPI.php?controller=locationsController&method=update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}

export async function UseLocationsDelete(id: string) {
  async function fetchData() {
    var data: IlocationData = await fetch(apiUrl + 'userAPI.php?controller=locationsController&method=delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    }).then((res) => {
      return res.json()
    })
    return data
  }
  return fetchData()
}
