import { FC, MutableRefObject, useCallback, useMemo, useState } from 'react'
import { Modal } from '../../components'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { Iusers } from '../../API/types'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import { UseUsersCreate, UseUsersUpdate, userStatusList } from '../../API'

interface CreateNewUserProps {
  dataGridRef: MutableRefObject<GridApiCommunity>
  handleClickClose: () => void
  usersData: Iusers[]
  id: number
}

const CreateNewUser: FC<CreateNewUserProps> = ({ dataGridRef, handleClickClose, usersData, id }) => {
  const { t } = useTranslation('')

  const selectedUser = useMemo(() => {
    return usersData.find((location) => location.id === id)
  }, [id, usersData])

  const [login, setLogin] = useState<string>(selectedUser ? selectedUser.login : '')
  const [name, setName] = useState<string>(selectedUser ? selectedUser.name : '')
  const [surename, setSurename] = useState<string>(selectedUser ? selectedUser.surename : '')
  const [email, setEmail] = useState<string>(selectedUser ? selectedUser.email : '')
  const [password, setPassword] = useState<string>(selectedUser ? selectedUser.password : '')
  const [userStatus, setUserStatus] = useState<string>(selectedUser ? selectedUser.userStatus : '')

  const handleClose = useCallback(() => {
    handleClickClose()
  }, [handleClickClose])

  const handleAccept = useCallback(async () => {
    if (selectedUser) {
      const rawUser: Iusers = { ...selectedUser, name, surename, email, password, userStatus }
      const updatedUser = await UseUsersUpdate(rawUser)

      selectedUser.name = updatedUser.name
      selectedUser.surename = updatedUser.surename
      selectedUser.email = updatedUser.email
      selectedUser.password = updatedUser.password
      selectedUser.userStatus = updatedUser.userStatus
    } else {
      const rowIds = dataGridRef.current.getAllRowIds()

      const rawUser: Iusers = {
        id: rowIds.length + 1,
        login: login,
        name: name,
        surename: surename,
        email: email,
        password: password,
        userStatus: userStatus,
      }

      const newUser = await UseUsersCreate(rawUser)

      dataGridRef.current.updateRows([newUser])
      //usersData = [...usersData, newUser]
    }
    handleClickClose()
  }, [dataGridRef, login, name, surename, email, password, userStatus, handleClickClose, selectedUser /*, usersData*/])

  return (
    <Modal
      isOpen={true}
      handleClose={handleClose}
      handleAccept={handleAccept}
      dialogTitle={id === 0 ? t('Pievienot jaunu lietotaju') : t('Redigēt lietotaju')}
      acceptTitle={id === 0 ? t('Pievienot') : t('Saglabāt')}
      closeTitle={t('Atcelt')}
    >
      <TextField
        sx={{ marginTop: 2 }}
        label={'Logins'}
        size={'small'}
        fullWidth
        variant='outlined'
        value={login}
        disabled={id !== 0}
        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setLogin(event.target.value)
        }}
      />
      <TextField
        sx={{ marginTop: 2 }}
        label={'Vārds'}
        size={'small'}
        fullWidth
        variant='outlined'
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setName(event.target.value)
        }}
      />
      <TextField
        sx={{ marginTop: 2 }}
        label={'Uzvārds'}
        size={'small'}
        fullWidth
        variant='outlined'
        value={surename}
        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setSurename(event.target.value)
        }}
      />
      <TextField
        sx={{ marginTop: 2 }}
        label={'Epasts'}
        size={'small'}
        fullWidth
        variant='outlined'
        value={email}
        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setEmail(event.target.value)
        }}
      />
      <TextField
        sx={{ marginTop: 2 }}
        label={'Parole'}
        size={'small'}
        fullWidth
        variant='outlined'
        type={'password'}
        value={password}
        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setPassword(event.target.value)
        }}
      />
      <FormControl sx={{ marginTop: 2 }} fullWidth={true} size='small'>
        <InputLabel>Lietotāja tips</InputLabel>
        <Select
          value={userStatus}
          label='Lietotāja tips'
          onChange={(event: SelectChangeEvent) => {
            setUserStatus(event.target.value)
          }}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {userStatusList.map((status) => (
            <MenuItem key={`userStatusId-${status.status}`} value={status.status}>
              {status.statusName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Modal>
  )
}

export default CreateNewUser
