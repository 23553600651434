import { FC, MutableRefObject, useCallback, useMemo } from 'react'
import { Modal } from '../../components'
import { useTranslation } from 'react-i18next'
import { Iusers } from '../../API/types'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import { UseUsersDelete } from '../../API'

interface DeleteUserProps {
  dataGridRef: MutableRefObject<GridApiCommunity>
  handleClickClose: () => void
  usersData: Iusers[]
  id: number
}

const DeleteUser: FC<DeleteUserProps> = ({ dataGridRef, handleClickClose, usersData, id }) => {
  const { t } = useTranslation('')

  const selectedUser = useMemo(() => {
    return usersData.find((location) => location.id === id)
  }, [id, usersData])

  const handleClose = useCallback(() => {
    handleClickClose()
  }, [handleClickClose])

  const handleAccept = useCallback(async () => {
    if (selectedUser?.id) {
      dataGridRef.current.updateRows([{ id: selectedUser?.id, _action: 'delete' }])

      await UseUsersDelete(selectedUser.id.toString())
    }
    /*
    usersData.splice(
      usersData.findIndex((item) => item.id === id),
      1,
    )
*/

    handleClickClose()
  }, [dataGridRef, /*usersData, id*/ selectedUser, handleClickClose])

  return (
    <Modal
      isOpen={true}
      handleClose={handleClose}
      handleAccept={handleAccept}
      dialogTitle={t(`Dzēst lietotāju - ${selectedUser?.name} ${selectedUser?.surename} (${selectedUser?.login})`)}
      acceptTitle={t(`Dzēst`)}
      closeTitle={t(`Atcelt`)}
    ></Modal>
  )
}

export default DeleteUser
