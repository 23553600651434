import { FC, useMemo } from 'react'
import { Card, Grid, IconButton, Link, Typography } from '@mui/material'
import {
  //EditNote,
  //Visibility,
  DeleteOutline,
  Edit,
  Settings,
  DoorSliding,
  Home,
  Close,
} from '@mui/icons-material'
import { Icontroller, IlocationData, IroomTypes } from '../../API/types'

interface LocationInfoProps {
  location: IlocationData
  controllers: Icontroller[]
  roomTypesData: IroomTypes[]
  handleClickOpenDelete: (locationId: number) => void
  handleClickOpenCreate: (locationId: number) => void
}

const LocationInfo: FC<LocationInfoProps> = ({
  location,
  controllers,
  roomTypesData,
  handleClickOpenDelete,
  handleClickOpenCreate,
}) => {
  const controllerData = useMemo(() => {
    return controllers.find((controller) => controller.controllerId === location.controllerId)
  }, [controllers, location.controllerId])

  return (
    <Card sx={{ paddingBottom: 0 }}>
      <Grid container spacing={0}>
        <Grid item xs={'auto'} style={{ width: 100, alignContent: 'center' }}>
          <Typography sx={{ fontSize: 14, textAlign: 'center', lineHeight: 1 }} color='text.secondary' gutterBottom>
            <Link underline='hover' color='inherit' href={'/location/' + location.id}>
              {location.locationId}
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          xs={'auto'}
          style={{ width: 200, alignContent: 'center', borderRight: 'solid #4e555a 1px', marginRight: 10 }}
        >
          <Typography sx={{ fontSize: 14, textAlign: 'center', lineHeight: 1 }} color='white' gutterBottom>
            <Link underline='hover' color='inherit' href={'/location/' + location.id}>
              {location.locationName}
            </Link>
          </Typography>
        </Grid>
        {/*
        <Grid
          item
          xs={'auto'}
          style={{ width: 150, alignContent: 'center', borderRight: 'solid #4e555a 1px', marginRight: 10 }}
        >
          <div
            style={{
              borderRadius: 5,
              display: 'inline-block',
              fontSize: 14,
              height: 25,
              textAlign: 'end',
              alignContent: 'center',
              paddingRight: 10,
              width: location.fillRate + '%',
              backgroundColor: '#456e42',
            }}
          >
            {location.fillRate} %
          </div>
        </Grid>
        <Grid item xs={'auto'} style={{ width: 150, alignContent: 'center', borderRight: 'solid #4e555a 1px' }}>
          <div
            style={{
              borderRadius: 5,
              display: 'inline-block',
              fontSize: 14,
              height: 25,
              textAlign: 'end',
              alignContent: 'center',
              paddingRight: 10,
              paddingLeft: 10,
              width: location.income,
              backgroundColor: '#ba6968',
              textWrap: 'nowrap',
            }}
          >
            {location.income} EUR
          </div>
        </Grid>

        <Grid item xs={'auto'} style={{ width: 90, alignContent: 'center', borderRight: 'solid #4e555a 1px' }}>
          <IconButton color='primary' component='div' sx={{}} disabled>
            <EditNote />
          </IconButton>
          <IconButton color='primary' component='div' sx={{}} disabled>
            <Visibility />
          </IconButton>
        </Grid>
*/}
        <Grid item xs={'auto'} style={{ width: 260, alignContent: 'center', borderRight: 'solid #4e555a 1px' }}>
          {controllerData &&
            controllerData.controllerLocks?.map((controller) => {
              const currentLock = location?.roomsList?.find((room) => room.lockId === controller.controllerLockId)
              const currentroomType = roomTypesData.find((roomType) => roomType.id === currentLock?.roomTypeId)

              //roomTypeId
              return (
                <IconButton
                  key={controller.controllerLockId}
                  color='success'
                  component='div'
                  sx={{ padding: 0, cursor: 'auto' }}
                  disabled={!currentLock || currentLock.roomTypeId === 0}
                  title={currentLock?.roomName}
                >
                  {currentroomType?.technicalRoom && <Settings />}
                  {currentroomType?.publicDoor && <DoorSliding />}
                  {!currentroomType?.technicalRoom && !currentroomType?.publicDoor ? (
                    !currentLock || currentLock.roomTypeId === 0 ? (
                      <Close />
                    ) : (
                      <Home />
                    )
                  ) : null}
                </IconButton>
              )
            })}
        </Grid>
        <Grid item xs={'auto'} style={{ width: 90, alignContent: 'center', borderRight: 'solid #4e555a 1px' }}>
          <IconButton
            color='success'
            component='div'
            sx={{}}
            onClick={() => {
              handleClickOpenCreate(location.id)
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            color='error'
            disabled={location.countSubLoc && location.countSubLoc > 0 ? true : false}
            component='div'
            sx={{}}
            onClick={() => {
              handleClickOpenDelete(location.id)
            }}
          >
            <DeleteOutline />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  )
}

export default LocationInfo
