import { FC, MutableRefObject, useCallback, useMemo } from 'react'
import { Modal } from '../../components'
import { useTranslation } from 'react-i18next'
import { IroomTypes } from '../../API/types'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import { UseRoomTypesDelete } from '../../API'

interface DeleteRoomTypeProps {
  dataGridRef: MutableRefObject<GridApiCommunity>
  handleClickClose: () => void
  roomTypesData: IroomTypes[]
  id: number
}

const DeleteRoomType: FC<DeleteRoomTypeProps> = ({ dataGridRef, handleClickClose, roomTypesData, id }) => {
  const { t } = useTranslation('')

  const location = useMemo(() => {
    return roomTypesData.find((location) => location.id === id)
  }, [id, roomTypesData])

  const handleClose = useCallback(() => {
    handleClickClose()
  }, [handleClickClose])

  const handleAccept = useCallback(async () => {
    if (location?.id) {
      dataGridRef.current.updateRows([{ id: location?.id, _action: 'delete' }])

      await UseRoomTypesDelete(location.id.toString())
    }
    /*
    roomTypesData.splice(
      roomTypesData.findIndex((item) => item.id === id),
      1,
    )
*/

    handleClickClose()
  }, [dataGridRef, /*roomTypesData, id*/ location, handleClickClose])

  return (
    <Modal
      isOpen={true}
      handleClose={handleClose}
      handleAccept={handleAccept}
      dialogTitle={t(`Dzēst telpu - ` + location?.roomTypeName)}
      acceptTitle={t(`Dzēst`)}
      closeTitle={t(`Atcelt`)}
    ></Modal>
  )
}

export default DeleteRoomType
