import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'

import RootPage from '../page/RootPage'
import { NotFound, Observation, Location, RoomTypes, Controllers, Payments, Users, Profile } from '../page'

export const routerConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootPage />}>
      <Route path='*' element={<NotFound />} />
      <Route index element={<Observation />} />
      <Route path='observation' element={<Observation />} />

      <Route path='location' element={<Location />}>
        <Route path='*' element={<Location />} />
      </Route>

      <Route path='roomTypes' element={<RoomTypes />} />
      <Route path='controllers' element={<Controllers />} />
      <Route path='payments' element={<Payments />} />
      <Route path='users' element={<Users />} />
      <Route path='profile' element={<Profile />} />
    </Route>,
  ),
)
